<template>
     <!-- 首页第一层 -->
    <div class="floor1">
      <!-- 首页版心 -->
      <div class="container floor1-container">
        <div class="floor1-logo">
          <div class="floor1-logo-top">
            <img src="@/assets/img/floor1-logo.png" alt="" />
            <div class="floor1-logo-text">
              <span class="floor1-logo-text-cn">加气人</span>
              <span class="floor1-logo-text-en">ACC&nbsp;PEOPLE</span>
            </div>
          </div>
          <!-- logo下方文字 -->
          <span class="floor1-logo-bottom-text">
         加气混凝土产业互联网平台
          </span>
        </div>
        <!-- 第一层中间搜索部分 -->
        <div class="floor1-search">
          <!-- 搜索部分的输入框 -->
          <div class="floor1-search-top">
            <img src="@/assets/img/floor1-search-logo.png" alt="" />
            <input
              type="text"
              placeholder="请输入商品名、服务名、品牌名、型号"
            />
            <button>搜索</button>
          </div>
          <!-- 第一层中间搜索部分(热点词) -->
          <div class="floor1-search-mid">
            <img src="@/assets/img/floor1-search-hot.png" alt="" />
            <span>灰加气</span>
            <span>砂加气</span>
            <span>振棒</span>
            <span>石灰</span>
            <span>石英砂</span>
            <span>铝粉</span>
          </div>
          <!--  第一层中间搜索部分(搜索记录)-->
          <div class="floor1-search-bottom">
            <span>历史</span>
            <span>石灰</span>
            <span>石英砂</span>
            <span>铝粉</span>
          </div>
        </div>
        <!-- 第一层进货车按钮 -->
        <button class="floor1-loading-truck">
          <img src="@/assets/img/floor1-loading-truck-icon.png" alt="" />
          <span>进货车</span>
        </button>
        <!-- 第一层客户热线 -->
        <span class="floor1-tel"> 客服热线：400-292-2999 </span>
      </div>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>
// 版心
.container {
    width: 1360px;
    margin: 0 auto;
}
// 首页第一层(搜索)
.floor1 {
    width: 100%;
    height: 250px;
    background-color: #FFFFFF;

    // 第一层(搜索) 版心
    .floor1-container {

        height: 250px;
        padding-top: 71px;
        box-sizing: border-box;
        display: flex;
        background-color: #FFFFFF;

        // 第一层左边logo
        .floor1-logo {
            width: 188px;
            height: 86px;
            // background-color: green;

            // 第一层左边logo 上部分
            .floor1-logo-top {
                display: flex;

                img {
                    width: 58px;
                    height: 58px;
                }

                .floor1-logo-text {
                    width: 68px;
                    height: 48px;

                    .floor1-logo-text-cn {
                        margin-left: 8px;
                        margin-top: 5px;
                        margin-bottom: 3px;
                        display: inline-block;
                        width: 66px;
                        height: 30px;
                        font-size: 22px;
                        font-family: Alibaba-PuHuiTi-B, Alibaba-PuHuiTi;
                        font-weight: normal;
                        color: #333333;
                        line-height: 30px;
                        user-select: none;
                    }

                    .floor1-logo-text-en {
                        margin-left: 8px;
                        margin-bottom: 13px;
                        display: inline-block;
                        width: 68px;
                        height: 15px;
                        font-size: 11px;
                        font-family: MicrosoftYaHei;
                        color: #333333;
                        line-height: 15px;
                        user-select: none;
                    }
                }
            }

            // 第一层左边logo 下部分文字
            .floor1-logo-bottom-text {
                height: 20px;
                font-size: 12px;
                font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
                font-weight: normal;
                color: #333333;
                line-height: 20px;
                user-select: none;
            }

        }

        // 第一层中间搜索部分
        .floor1-search {
            width: 806px;
            height: 108px;
            // background-color: green;
            margin-left: 24px;

            .floor1-search-top {
                width: 806px;
                height: 44px;
                position: relative;
                display: flex;

                img {
                    position: absolute;
                    left: 12px;
                    top: 10px;
                    width: 24px;
                    height: 24px;
                }

                input {
                    width: 696px;
                    height: 44px;
                    border: 1px #09ae9c solid;
                    outline: none;
                    border-radius: 4px 0 0 4px;
                    padding-left: 36px;
                }

                input::-webkit-input-placeholder {
                    /* WebKit browsers*/
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #A6A6A6;
                }


                button {
                    cursor: pointer;
                    width: 110px;
                    height: 46px;
                    background: #09ae9c;
                    border-radius: 4px;
                    border: 1px solid #09AE9C;
                    color: #FFFFFF;
                    font-size: 16px;
                    font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
                    font-weight: normal;
                    position: absolute;
                    right: -35px;
                    outline: none;
                }
            }

            .floor1-search-mid {
                margin-bottom: 12px;
                margin-top: 12px;
                width: 337px;
                height: 20px;
                display: flex;
                justify-content: space-between;
                align-items: center;

                img {
                    width: 22px;
                    height: 22px;
                }

                span {
                    cursor: pointer;
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #A6A6A6;

                }
            }

            .floor1-search-bottom {
                width: 200px;
                height: 20px;
                display: flex;
                align-items: center;
                justify-content: space-between;

                span {
                    cursor: pointer;
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #A6A6A6;
                }

                :nth-child(1) {
                    color: #09AE9C;
                }
            }
        }

        // 第一层进货车按钮
        .floor1-loading-truck {
            margin-left: 72px;
            width: 120px;
            height: 44px;
            background: #FFFFFF;
            border-radius: 4px;
            border: 1px solid #09AE9C;
            display: flex;
            margin-right: 40px;
            outline: none;

            img {
                width: 22px;
                height: 22px;
                margin-left: 13px;
                margin-top: 11px;
            }

            span {
                margin-top: 11px;
                margin-bottom: 11px;
                margin-left: 4px;
                width: 48px;
                height: 22px;
                font-size: 16px;
                font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
                font-weight: normal;
                color: #09AE9C;
                line-height: 22px;
            }
        }

        // 第一层客服热线
        .floor1-tel {
            width: 200px;
            height: 20px;
            font-size: 14px;
            font-family: Alibaba-PuHuiTi-R,
                Alibaba-PuHuiTi;
            font-weight: normal;
            color: #09AE9C;
            line-height: 20px;
            margin-top: 12px;

        }


    }
}
</style>