<template>
  <div class="box">
    <img :src="commodity" alt="" />
    <div class="commodity-name">杭州蒸压灰加气&nbsp;B03</div>
    <div class="commodity-price">
      <span>¥200-500</span>
      <img src="@/assets/img/price-rigth-icon.png" alt="" />
    </div>
    <div class="commodity-factory">
      <img src="@/assets/img/commodity-factory-icon.png" alt="" />
      <span>杭州莱特加气厂</span>
    </div>
    <div class="commodity-address">
      <span>杭州</span>
      <span>0.1km</span>
    </div>
  </div>
</template>

<script>
export default {
    props:["commodity"]
};
</script>

<style lang="scss" scoped>
.box {
  width: 170px;
  height: 277px;
  background: #f7f7f7;
  border-radius: 8px;
  padding: 8px;
  box-sizing: border-box;
  cursor: pointer;
  img {
    width: 154px;
    height: 154px;
  }
  .commodity-name {
    margin: 0 auto;
    margin-top: 8px;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #383838;
  }
  .commodity-price {
    width: 154px;
    height: 32px;
    display: flex;
    align-items: center;
    position: relative;
    span {
      width: 71px;
      font-size: 14px;
      font-family: DINAlternate-Bold, DINAlternate;
      font-weight: bold;
      color: #09ae9c;
    }
    img {
      width: 32px;
      height: 32px;
      position: absolute;
      right: 4px;
    }
  }
  .commodity-factory {
    width: 154px;
    height: 18px;
    margin-top: 2px;
    img {
      width: 18px;
      height: 18px;
    }
    span {
      font-size: 13px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      vertical-align: top;
      margin-left: 4px;
    }
  }
  .commodity-address {
    width: 154px;
    height: 17px;
    margin-top: 7px;
    position: relative;
    span {
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
      vertical-align: top;
      
    }
    :nth-child(2) {
      position: absolute;
      right: 0;
    }
  }
}
</style>