<template>
<!-- 头部 -->
  <div class="header">
    <!-- 头部中间 -->
    <div class="header-center">
      <!-- 头部版心 -->
      <div class="container header-container">
        <img
          src="@/assets/img/header-location.png"
          class="header-location-icon"
          alt=""
        />
        <span class="header-location-text">欧美金融城T1</span>
        <span class="header-welcome">加气人欢迎您！</span>
        <div class="header-official-website">
          <span>加气人官网</span>
          <img src="@/assets/img/header-right1.png" alt="" />
        </div>
        <div class="header-app-icon"></div>
        <a href="" class="header-app-text">加气人APP</a>

        <div class="header-applet-icon"></div>
        <a href="" class="header-applet-text">小程序</a>

        <div class="header-list">
          <a href="">加气人首页</a>
          <a href="">采购中心</a>
          <a href="">供应商</a>
          <a href="">客服服务</a>
          <a href="">联系我们</a>
      <router-link to="/useragreement">用户协议</router-link>
        <router-link to="/privacyagreement">隐私协议</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="scss" src="@/assets/css/index.scss" />