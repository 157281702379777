import Vue from 'vue'
import VueRouter from 'vue-router'
import  home from '../views/home.vue'

Vue.use(VueRouter)

const routes = [
{
  path: '/',
  name: 'home',
  component: home,
},
{
  path: '/useragreement',
  name: 'useragreement',
  component: () => import('@/views/userAgreement.vue'),
},
{
  path: '/privacyagreement',
  name: 'privacyagreement',
  component: () => import('@/views/privacyAgreement.vue'),
},

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

