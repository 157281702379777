<template>
    <div class="footer">
        <div class="footer-container">
<img src="@/assets/img/footer-logo.png" alt="">

</div>
<div class="footer-line"></div>
    <p>
        Copyright ©2021杭州家气人科技有限公司联系电话：0571-8854-7788
        合作邮箱：15224744@qq.com       
      </p>
      <p>浙ICP备2023000362号-2</p>

    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>
.footer{
    width: 100%;
    height: 353px;
    background-color: #ffffff;
    .footer-container{
        width: 1360px;
        height: 230px;
    margin: 0 auto;
  img{
        width: 307px;
        height: 156px;
        margin-top: 37px;
        margin-left: 515px;
    }   

    }
    .footer-line{
    width: 100%;
    height: 1px;
    background: #D8D8D8;
}
:nth-child(3){
 margin-top: 32px;
font-size: 18px;
font-family: MicrosoftYaHei;
color: #333333;
line-height: 24px;
text-align: center;
}
:nth-child(4){
     margin-top: 10px;
font-size: 18px;
font-family: MicrosoftYaHei;
color: #333333;
line-height: 24px;
text-align: center;
}
  
}
</style>