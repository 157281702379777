<template>
  <div class="classify-item">
    <div class="classify-item-top">
      <img :src="topIcon" alt="" />
      <span>{{ topText }}</span>
    </div>
    <div class="classify-item-bottom">
      <span>{{ bottomText1 }}</span>
      <span>{{ bottomText2 }}</span>
      <img src="@/assets/img/classify-item-bottom-icon.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    topText: { type: String, required: true },
    topIcon: { type: String },
    bottomText1: { type: String },
    bottomText2: { type: String },
  },
};
</script>

<style lang="scss" scoped>
.classify-item {
  width: 198px;
  height: 58px;
  margin-top: 20px;
  margin-bottom: 10px;
  background-color: #ffffff;
  position: relative;

  .classify-item-top {
    margin-left: 12px;
    display: flex;
    align-items: center;
    img {
      width: 20px;
      height: 20px;
    }
    span {
      cursor: pointer;
      width: 70px;
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
      margin-left: 8px;
    }
  }
  .classify-item-bottom {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    height: 20px;
    :nth-child(1) {
      margin-left: 52px;
      cursor: pointer;
    }
    :nth-child(2) {
      cursor: pointer;
      margin-left: 16px;
    }
    img {
      width: 16px;
      height: 16px;
     position: absolute;
     right: 12px;
    }
  }
}
</style>