<template>
  <div class="box">
    <div class="box-top">
      <img :src="headPortrait" alt="" />
      <img src="@/assets/img/artisan-sex.png" alt="" />
    </div>

    <div class="artisan-name">{{ artisanName }}</div>
    <div class="artisan-experience">5年经验</div>
    <div class="artisan-grade">一级工艺师</div>
    <div class="artisan-materials">{{ artisanMaterials }}</div>
  </div>
</template>

<script>
export default {
  props: ["headPortrait", "artisanName", "artisanMaterials"],
};
</script>

<style lang="scss" scoped>
.box {
  width: 112px;
  height: 172px;
  cursor: pointer;
  .box-top {
    width: 54px;
    height: 54px;
    margin-left: 29px;
    margin-top: 12px;
    position: relative;
    :nth-child(1) {
      width: 54px;
      height: 54px;
    }
    :nth-child(2) {
      width: 18px;
      height: 18px;
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }

  .artisan-name {
    margin-top: 11px;
    margin-left: 42px;
    font-size: 14px;
    font-weight: 500;
    color: #383838;
    text-align: center;
    margin: 0 auto;
  }
  .artisan-experience {
    margin-top: 2px;
    margin-left: 31px;
    font-weight: 400;
    color: #999999;
    font-size: 13px;
    margin: 0 auto;
    text-align: center;
  }
  .artisan-grade {
    width: 76px;
    height: 20px;
    background: rgba(9, 174, 156, 0.1);
    border-radius: 2px;
    margin-top: 2px;
    margin-left: 18px;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    color: #09ae9c;
    font-weight: 400;
  }
  .artisan-materials {
    width: 68px;
    height: 18px;
    font-size: 13px;
    font-weight: 400;
    color: #666666;
    margin-top: 2px;
    margin-left: 22px;
    text-align: center;
  }
}
</style>