<template>
  <div>
           <page-header></page-header>
<floor1-search></floor1-search>
<router-view></router-view>
    <!-- 首页第二层  轮播图以及用户信息-->
    <div class="floor2">
      <div class="container floor2-container">
        <!-- 第二层左部(分类) -->
        <div class="floor2-classify">
          <!-- 所有分类 -->
          <div class="floor2-classify-top">
            <img src="@/assets/img/floor2-classify-top-icon.png" alt="" />
            <span>所有分类</span>
          </div>
          <floor2-classify
            topText="加气混凝土"
            :topIcon="require('@/assets/img/floor2-classify-icon1.png')"
            bottomText1="灰加气"
            bottomText2="砂加气"
          ></floor2-classify>
          <floor2-classify
            topText="原材料"
            :topIcon="require('@/assets/img/floor2-classify-icon2.png')"
            bottomText1="铝粉"
            bottomText2="水泥"
          ></floor2-classify>
          <floor2-classify
            topText="生产设备"
            :topIcon="require('@/assets/img/floor2-classify-icon3.png')"
            bottomText1="蒸压釜"
            bottomText2="切割机"
          ></floor2-classify>
          <floor2-classify
            topText="耗材"
            :topIcon="require('@/assets/img/floor2-classify-icon4.png')"
            bottomText1="电源"
            bottomText2="控制器"
          ></floor2-classify>
        </div>
        <!-- 第二层中间部分 -->
        <div class="floor2-center">
          <!-- 第二层中间部分导航栏 -->
          <div class="floor2-center-bar">
            <a href="">首页</a>
            <a href="">加气混凝土</a>
            <a href="">原材料</a>
            <a href="">设备</a>
            <a href="">耗材</a>
            <a href="">工艺师</a>
            <a href="">加气砖厂</a>
            <a href="">原料设备商</a>
            <img src="@/assets/img/floor2-center-bar.png" alt="" />
          </div>
          <div class="floor2-center-swiper">
            <img src="@/assets/img/swiper-img.png" alt="">
          </div>
        </div>
        <!-- 第二层右边部分 -->
        <div class="floor2-right">
          <!-- 第二层右边部分第一层  用户信息-->
          <div class="floor2-right-f1">
            <!-- 用户头像 -->
            <img src="@/assets/img/floor2-right-head-portrait.png" alt="" />
            <!-- 右边第一层中间 -->
            <div class="floor2-right-f1-center">
              <!-- 用户名字以及认证状态 -->
              <div class="floor2-right-f1-username">
                <span>应秋莱</span>
                <!-- 已认证图标 -->
                <img
                  v-if="true"
                  src="@/assets/img/floor2-right-authenticated.png"
                  alt=""
                />
                <!-- 未认证图标 -->
                <img
                  v-if="false"
                  src="@/assets/img/floor2-right-unverified.png"
                  alt=""
                />
              </div>
              <!-- 公司名称 -->
              <span>杭州加气砖有限公司</span>
            </div>
            <!-- 右边第一层右边 -->
            <div class="floor2-right-f1-right">
              <img src="@/assets/img/switch-account.png" alt="" />
              <img src="@/assets/img/floor2-right-f1-right-icon.png" alt="" />
            </div>
          </div>
          <!-- 第二层右边部分第二层  VIP-->
          <img src="@/assets/img/floor2-right-vip.png" alt="" />
          <!-- 第二层右边部分第三层  订单状态-->
          <div class="floor2-right-f3">
            <!-- 订单 -->
            <div class="floor2-right-f3-top">
              <span>商品订单</span>
              <span>工艺师订单</span>
              <span>全部</span>
              <img src="@/assets/img/floor2-right-f3-icon.png" alt="" />
            </div>
            <div class="floor2-right-f3-bottom">
              <div class="floor2-right-order-status">
                <img src="@/assets/img/obligation-icon.png" alt="" />
                <div>待付款</div>
              </div>
              <div class="floor2-right-order-status">
                <img src="@/assets/img/obligation-icon.png" alt="" />
                <div>待发货</div>
              </div>
              <div class="floor2-right-order-status">
                <img src="@/assets/img/obligation-icon.png" alt="" />
                <div>待收货</div>
              </div>
              <div class="floor2-right-order-status">
                <img src="@/assets/img/obligation-icon.png" alt="" />
                <div>已完成</div>
              </div>
              <div class="floor2-right-order-status">
                <img
                  src="@/assets/img/obligation-icon.png"
                  class="order-status-last"
                  alt=""
                />
                <div>售货服务</div>
              </div>
            </div>
          </div>
          <!-- 第二层右边第四层 相关设置 -->
          <div class="floor2-right-f4">
            <div class="floor2-right-f4-item">
              <!-- 地址管理 -->
              <img src="@/assets/img/address-administration-icon.png" alt="" />
              <span>地址管理</span>
              <img
                src="@/assets/img/right-arrow-icon.png"
                class="right-arrow"
                alt=""
              />
            </div>
            <div class="floor2-right-f4-item">
              <img
                src="@/assets/img/enterprise-certification-icon.png"
                alt=""
              />
              <span>企业认证</span>
              <img
                src="@/assets/img/right-arrow-icon.png"
                class="right-arrow"
                alt=""
              />
            </div>
            <div class="floor2-right-f4-item">
              <img src="@/assets/img/official-service-icon.png" alt="" />
              <span>官方客服</span>
              <img
                src="@/assets/img/right-arrow-icon.png"
                class="right-arrow"
                alt=""
              />
            </div>
            <div class="floor2-right-f4-item">
              <img src="@/assets/img/set-icon.png" alt="" />
              <span>设置</span>
              <img
                src="@/assets/img/right-arrow-icon.png"
                class="right-arrow"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 首页第三层 -->
 
    <div class="floor3">

      <div class="container floor3-container">

        <div class="floor3-artisan-classify">
          <span
            :class="{ active: classifyNow == i }"
            @click="classifyNow = i"
            v-for="(classify, i) in artisanClassify"
            :key="i"
          >
            {{ classify }}
          </span>
        </div>
      
  
        <div class="floor3-artisan-items">
          <floor3-artisan
            :headPortrait="require('@/assets/img/artisan-head-portrait1.png')"
            artisanName="丘山"
            artisanMaterials="灰加气板材"
          ></floor3-artisan>
          <floor3-artisan
            :headPortrait="require('@/assets/img/artisan-head-portrait2.png')"
            artisanName="闫桂"
            artisanMaterials="灰加气砌砖"
          ></floor3-artisan>
          <floor3-artisan
            :headPortrait="require('@/assets/img/artisan-head-portrait3.png')"
            artisanName="师清"
            artisanMaterials="砂加气板材"
          ></floor3-artisan>
          <floor3-artisan
            :headPortrait="require('@/assets/img/artisan-head-portrait4.png')"
            artisanName="权荔"
            artisanMaterials="砂加气砌块"
          ></floor3-artisan>
          <floor3-artisan
            :headPortrait="require('@/assets/img/artisan-head-portrait5.png')"
            artisanName="代博"
            artisanMaterials="灰加气板材"
          ></floor3-artisan>
          <floor3-artisan
            :headPortrait="require('@/assets/img/artisan-head-portrait6.png')"
            artisanName="台玉凝"
            artisanMaterials="灰加气板材"
          ></floor3-artisan>
          <floor3-artisan
            :headPortrait="require('@/assets/img/artisan-head-portrait7.png')"
            artisanName="佟可"
            artisanMaterials="砂加气砌块"
          ></floor3-artisan>
          <floor3-artisan
            :headPortrait="require('@/assets/img/artisan-head-portrait8.png')"
            artisanName="年悦"
            artisanMaterials="灰加气板材"
          ></floor3-artisan>
          <floor3-artisan
            :headPortrait="require('@/assets/img/artisan-head-portrait9.png')"
            artisanName="年悦"
            artisanMaterials="灰加气板材"
          ></floor3-artisan>
          <floor3-artisan
            :headPortrait="require('@/assets/img/artisan-head-portrait10.png')"
            artisanName="年悦"
            artisanMaterials="灰加气砌块"
          ></floor3-artisan>
        </div>
      </div>
    </div>
  
    <!-- 首页第四层 -->
    <div class="floor4">
     
      <div class="container floor4-container">
       
        <div class="floor4-commodity-classify">
          <span
            :class="{ active: commodityNow == i }"
            @click="commodityNow = i"
            v-for="(commodity, i) in commodityClassify"
            :key="i"
          >
            {{ commodity }}
          </span>
        </div>
        <div class="floor4-commodity-items">
          <floor4-commodity
          :commodity="require('@/assets/img/floor4-commodity1.png')"
          ></floor4-commodity>
          <floor4-commodity
           :commodity="require('@/assets/img/floor4-commodity2.png')"
          ></floor4-commodity>
          <floor4-commodity
           :commodity="require('@/assets/img/floor4-commodity1.png')"
           ></floor4-commodity>
          <floor4-commodity
           :commodity="require('@/assets/img/floor4-commodity2.png')"
           ></floor4-commodity>
          <floor4-commodity
           :commodity="require('@/assets/img/floor4-commodity1.png')"
           ></floor4-commodity>
          <floor4-commodity
           :commodity="require('@/assets/img/floor4-commodity2.png')"
           ></floor4-commodity>
          <floor4-commodity
           :commodity="require('@/assets/img/floor4-commodity2.png')"
           ></floor4-commodity>
          <floor4-commodity
           :commodity="require('@/assets/img/floor4-commodity1.png')"
           ></floor4-commodity>
          <floor4-commodity
           :commodity="require('@/assets/img/floor4-commodity2.png')"
           ></floor4-commodity>
          <floor4-commodity
           :commodity="require('@/assets/img/floor4-commodity1.png')"
           ></floor4-commodity>
          <floor4-commodity
           :commodity="require('@/assets/img/floor4-commodity2.png')"
           ></floor4-commodity>
          <floor4-commodity
           :commodity="require('@/assets/img/floor4-commodity1.png')"
           ></floor4-commodity>
          <floor4-commodity
           :commodity="require('@/assets/img/floor4-commodity2.png')"
           ></floor4-commodity>
          <floor4-commodity
           :commodity="require('@/assets/img/floor4-commodity2.png')"
           ></floor4-commodity>
        </div>
      </div>
    </div>
    <page-footer></page-footer>
  </div>
</template>

<script>
import Floor2Classify from "@/components/Floor2Classify.vue";
import Floor3Artisan from "@/components/Floor3Artisan.vue";
import Floor4Commodity from "@/components/Floor4Commodity.vue";
import PageHeader from '@/components/PageHeader.vue'
import Floor1Search from '@/components/Floor1Search.vue'
import PageFooter from '@/components/PageFooter.vue'
export default {
  components: {
    Floor2Classify,
    Floor3Artisan,
    Floor4Commodity,
    PageHeader,
    Floor1Search,
    PageFooter
  },
  data() {
    return {
      artisanClassify: ["全部工艺师", "一级工艺师", "二级工艺师", "三级工艺师"],
      classifyNow: 0,
      commodityClassify: ["推荐", "灰加气", "砂加气", "原材料"],
      commodityNow: 0,
    };
  },
};
</script>


<style scoped lang="scss" src="@/assets/css/index.scss" />